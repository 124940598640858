<template>
  <v-row>
 <v-col>
      <base-card>
        <v-card-title>Usuarios</v-card-title>
        <v-card-text>
            <v-row>
          
            <v-col cols="5">
<v-text-field          
                v-model="usuario.username"
                class="uppercase"
                label="Nombre de Usuario"
              />
            </v-col>
            <v-col cols="5">

                 <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-2"
                            label="Password"
                            v-model="usuario.password"
                            @click:append="show = !show"
                        ></v-text-field>
            </v-col>
            </v-row>
        
       

            <v-col cols="4">
                 <v-select
                v-model="usuario.idprofile"
                                @change="getidProfile"
 item-text="profile"
                      :items="lista_perfiles"
                      item-value="idprofile"
                      label="Perfil"
                    >


                    </v-select>
              
          </v-col>

          <v-row>
            <v-col cols="4">
              <v-btn color="primart">
        <v-icon>mdi-content-save-outline</v-icon>

                Guardar
              </v-btn>
            </v-col>
            <v-col cols="4" v-if="edit">
              <v-btn color="danger">
                  <v-icon>mdi-close-circle-outline</v-icon>
                Cancelar Edición
              </v-btn>
            </v-col>
          </v-row>
          <br />

          <!--  <div class="form-group row">
        <label class="col-sm-2 col-form-label">Buscar alumno:</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" placeholder="Nombre">
        </div>
      </div>
          </div>-->
           <v-row>
           <v-col cols="4">
              <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Búsqueda"
                        single-line
                        hide-details
                    ></v-text-field>
           </v-col>
       </v-row>
          <br />

          <v-data-table
                 locale="es"
                    :search="search"
                    :headers="headers"
                    :items="lista_usuarios"
                    item-key="idperfil"
                    
                    class="elevation-1 table-one"
                    multi-sort
                >
                   </v-data-table>
         
        </div>
        </v-card-text>
        </base-card>
    </v-col>

  </v-row>
</template>
<script>

import { url } from "../../main.js";

export default {
  data() {
    return {
         headers: [
                {
                    text: 'Usuario',
                    align: 'start',
                    sortable: false,
                    value: 'user'
                },
                {
                    
                    text: 'Perfil',
                    sortable: false,
                    value: 'profile'
            

                }
         ],
         show: false,
      usuario: {
        username: "",
        password: "",
        idprofile: 0,
      },
      edit: false,
      nombre: "",

      busqueda_nombre: "",
      lista_perfiles: [],
      lista_usuarios: []
    }
  },
  mounted(){
console.log("mounted");
    this.get_perfiles();

  },
  components: {
   
  },

  computed: {
    
  },

  methods: {
    get_perfiles() {
      this.axios
        .get(url + "apiUsers.php/users")
        .then((respuesta) => {
          console.log(respuesta);
          this.lista_usuarios = respuesta.data;

        })
        .catch(function () {
          console.log("FAILURE ver!!");
        });
    },
    saveUsuario() {},

    limpiarcampos() {
      /*  this.cliente = {};
      this.nombre_cliente="";*/
    },
    validarcampos() {
      /* if (
        this.cliente.nombre == "" ||
        this.cliente.apellido == "" 
        //||
       // this.cliente.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }*/
    },
    getidProfile(e) {
      if (e.target.options.selectedIndex > -1) {
        this.usuario.idprofile = this.lista_perfiles[
          e.target.options.selectedIndex
        ].idprofile;
      }
    },
    clear_busqueda_usuario() {
      this.busqueda_nombre = "";
    },

    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage * this.pageSize < this.lista_clientes.length)
        this.currentPage++;
    },
    sort(s) {
      console.log("sort");
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    editCliente(idcliente) {
      this.axios
        .get(url + "apiClientes.php/get_cliente/" + idcliente)
        .then((respuesta) => {
          console.log(respuesta);
          this.cliente = respuesta.data;
          this.edit = true;
        });
    },
    eliminarCliente(cliente) {
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar al cliente?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(url + "apiClientes.php/delete_cliente", cliente)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("Cliente Eliminado");
                this.verClientes();
              }
            });
        }
      });
    },

    cancelarEdicion() {
      this.edit = false;
      this.cliente = {};
    },
  },
};
</script>
